/* eslint-disable react/no-multi-comp */
import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import LazyLoad from 'react-lazyload';
import ReactGA from 'react-ga';

const load = () => {
  if (typeof window !== 'undefined') {
    try {
      ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
    } catch (error) {
      ReactGA.exception({
        description: `Ads - ${error.toString()} => ${window.location.pathname}`,
        fatal: true,
      });
      console.error('Error loading ads', error);
    }
  }
};

const AdsenseLazy = memo(
  ({
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type '{ chi... Remove this comment to see the full error message
    className = '',
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'style' does not exist on type '{ childre... Remove this comment to see the full error message
    style = { display: 'block' },
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'client' does not exist on type '{ childr... Remove this comment to see the full error message
    client,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'slot' does not exist on type '{ children... Remove this comment to see the full error message
    slot,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'layout' does not exist on type '{ childr... Remove this comment to see the full error message
    layout = '',
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'format' does not exist on type '{ childr... Remove this comment to see the full error message
    format = 'auto',
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'responsive' does not exist on type '{ ch... Remove this comment to see the full error message
    responsive = 'false',
  }) => {
    useEffect(() => {
      load();
    }, []);

    return (
      <ins
        className={`${className} adsbygoogle`}
        style={style}
        data-ad-client={client}
        data-ad-slot={slot}
        data-ad-layout={layout}
        data-ad-format={format}
        data-full-width-responsive={responsive}
      />
    );
  }
);

(AdsenseLazy as any).propTypes = {
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  client: PropTypes.string.isRequired,
  slot: PropTypes.string.isRequired,
  layout: PropTypes.string,
  format: PropTypes.string,
  responsive: PropTypes.string,
};

// @ts-expect-error ts-migrate(2339) FIXME: Property 'lazyLoad' does not exist on type '{ chil... Remove this comment to see the full error message
const Adsense = memo(({ lazyLoad = false, ...rest }) => {
  if (!lazyLoad) {
    return <AdsenseLazy {...rest} />;
  }

  return (
    <LazyLoad key={`wrapper-ads-${(rest as any).key}`} once offset={400}>
      <AdsenseLazy {...rest} />
    </LazyLoad>
  );
});

(Adsense as any).propTypes = {
  lazyLoad: PropTypes.bool,
};

export default Adsense;
