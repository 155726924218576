import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import classNames from 'clsx';
import formatDistance from 'date-fns/formatDistance';
import IconStar from 'components/Icons/Star';
import Tooltip from '@mui/material/Tooltip';
import MiddotDivider from 'components/MiddotDivider/MiddotDivider';
import useLocale from 'hooks/useLocale';
import { useIntl } from 'react-intl';
import getImage from 'utils/getImageLink';

const useStyles = makeStyles(
  (theme: any) => ({
    containerContent: {
      flex: '1 1 auto',
      marginRight: 24,
    },

    pubAvatar: {
      borderRadius: 4,
      width: 20,
      height: 20,
      verticalAlign: 'middle',
      display: 'inline-block',
      overflow: 'hidden',
    },

    containerImageLeft: {},
    titlePlaceHolderSizelarge: {},

    titlePlaceHolder: {
      margin: 0,
      fontSize: 24,
      fontWeight: 600,
      lineHeight: '28px',
      wordBreak: 'break-word',
      wordWrap: 'break-word',
      transform: 'translateY(-.72px)',
      letterSpacing: '-.42px',
      maxHeight: 84,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
    },

    titlePlaceHolderSizesmall: {
      fontSize: 18,
      maxHeight: 40,
      '-webkit-line-clamp': 2,
      lineHeight: '20px',
      transform: 'translateY(.96px)',
      letterSpacing: '-.17px',
      marginBottom: 4,
    },

    titleAndSubtitleContainer: {},

    titlePlaceHolderSizemedium: {
      fontSize: 21,
      maxHeight: 48,
      '-webkit-line-clamp': 2,
      lineHeight: '24px',
      transform: 'translateY(-1.88px)',
      letterSpacing: '-.29px',
      marginBottom: 4,
    },

    [theme.breakpoints.down('md')]: {
      titlePlaceHolder: {
        maxHeight: 60,
        fontSize: 18,
        lineHeight: '20px',
        '-webkit-transform': 'translateY(.96px)',
        transform: 'translateY(.96px)',
        letterSpacing: '-.17px',
      },
      titlePlaceHolderSizesmall: {
        maxHeight: 40,
      },
      titlePlaceHolderSizelarge: {
        maxHeight: 72,
        fontSize: 21,
        lineHeight: '24px',
        '-webkit-transform': 'translateY(-1.88px)',
        transform: 'translateY(-1.88px)',
        letterSpacing: '-.29px',
      },
    },

    fontSize38: {
      fontSize: 38,
    },

    subtitle: {
      fontSize: 16,
      lineHeight: '20px',
      paddingTop: 2,
      maxHeight: 40,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      transform: 'translateY(2.52px)',
    },

    subtitleSizesmall: {
      // whiteSpace: 'nowrap',
      transform: 'translateY(4.52px)',
    },

    borderBottom: {
      paddingTop: 32,
      borderBottom: '1px solid rgba(0,0,0,.05)',
      clear: 'both',
    },

    linkImage: {
      minHeight: 100,
      display: 'block',
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      backgroundOrigin: 'border-box',
      backgroundPosition: '50% 50%',
    },

    linkImageWithBackground: {
      border: '1px solid rgba(0,0,0,.15)',
      background: '#f0f0f0',
    },

    containerFooter: {
      marginTop: 12,
    },

    linkPostMeta: {
      '&:hover': {
        textDecoration: 'underline',
      },
    },

    containerPostMetaTop: {
      display: 'flex',
      fontSize: 15,
      alignItems: 'center',
      flexDirection: 'row',
      paddingBottom: theme.spacing(1),
    },
    containerPostMetaRightTop: {
      display: 'flex',
      fontSize: 15,
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    containerPostMeta: {
      fontSize: 15,
    },

    postMeta: {
      fontSize: 15,
    },

    imagePreview: {
      width: 152,
      flex: '0 0 auto',
    },

    imagePreviewSizelarge: {
      height: 150,
      flex: '0 1 auto',
      marginBottom: 16,
      width: '100%',
    },

    imagePreviewSizemedium: {
      height: 150,
      flex: '0 1 auto',
      marginBottom: 16,
      width: '100%',
      '& $linkImage': {
        backgroundPosition: '51% 36%',
      },
    },

    imagePreviewSizesmall: {
      height: 100,
      flex: '0 0 auto',
      width: 100,
    },

    containerContentImagePositionleft: {
      marginLeft: 24,
      marginRight: 0,
    },

    containerContentSizelarge: {
      flex: '0 1 auto',
    },

    spanIconStar: {
      display: 'inline-block',
      verticalAlign: 'middle',
      lineHeight: '14px',
    },

    iconStar: {
      fontSize: 15,
    },

    arrowPopper: {
      zIndex: 1,
      '&[x-placement*="bottom"] $arrowArrow': {
        top: 0,
        left: 0,
        marginTop: '-0.9em',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '0 1em 1em 1em',
          borderColor: 'transparent transparent rgba(49,49,47,.99) transparent',
        },
      },
      '&[x-placement*="top"] $arrowArrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-0.9em',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '1em 1em 0 1em',
          borderColor: 'rgba(49,49,47,.99) transparent transparent transparent',
        },
      },
      '&[x-placement*="right"] $arrowArrow': {
        left: 0,
        marginLeft: '-0.9em',
        height: '3em',
        width: '1em',
        '&::before': {
          borderWidth: '1em 1em 1em 0',
          borderColor: 'transparent rgba(49,49,47,.99) transparent transparent',
        },
      },
      '&[x-placement*="left"] $arrowArrow': {
        right: 0,
        marginRight: '-0.9em',
        height: '3em',
        width: '1em',
        '&::before': {
          borderWidth: '1em 0 1em 1em',
          borderColor: 'transparent transparent transparent rgba(49,49,47,.99)',
        },
      },
    },

    arrowArrow: {
      position: 'absolute',
      fontSize: 7,
      width: '3em',
      height: '3em',
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
      },
    },

    containerSizelarge: {
      '& $containerContentImagePositionleft': {
        marginLeft: 88,
        '@media screen and (max-width: 767px)': {
          marginLeft: 0,
          marginRight: 164,
        },
        '@media screen and (max-width: 503px)': {
          marginRight: 104,
        },
      },
    },

    containerSizemedium: {
      '& $containerContentImagePositionleft': {
        marginLeft: 0,
        marginRight: 0,
      },
    },

    containerSizesmall: {
      '& $titleAndSubtitleContainer': {
        maxHeight: 50,
        overflow: 'hidden',
      },
    },

    '@media screen and (max-width: 855px)': {
      imagePreview: {
        width: 140,
      },
      imagePreviewSizelarge: {
        height: 150,
        flex: '0 1 auto',
        marginBottom: 16,
        width: '100%',
      },
      linkImage: {},
      imagePreviewSizemedium: {
        height: 150,
        flex: '0 1 auto',
        marginBottom: 16,
        width: '100%',
        '& $linkImage': {
          backgroundPosition: '51% 36%',
        },
      },
    },

    '@media screen and (max-width: 767px)': {
      containerSizesmall: {
        '&$containerImageLeft': {
          flexDirection: 'row-reverse',
        },
        '& $containerContentImagePositionleft': {
          marginLeft: 0,
          marginRight: 24,
        },
      },
      imagePreview: {
        width: 140,
      },
      imagePreviewSizelarge: {
        height: 150,
        flex: '0 1 auto',
        marginBottom: 16,
        width: '100%',
      },
      linkImage: {},
      imagePreviewSizemedium: {
        height: 150,
        flex: '0 1 auto',
        marginBottom: 16,
        width: '100%',
        '& $linkImage': {
          backgroundPosition: '51% 36%',
        },
      },
    },

    '@media screen and (max-width: 503px)': {
      linkImage: {
        minHeight: 80,
      },
      imagePreview: {
        width: 80,
        height: 80,
      },
      imagePreviewSizelarge: {
        height: 150,
        flex: '0 1 auto',
        marginBottom: 16,
        width: '100%',
      },
      imagePreviewSizemedium: {
        height: 150,
        flex: '0 1 auto',
        marginBottom: 16,
        width: '100%',
        '& $linkImage': {
          backgroundPosition: '51% 36%',
        },
      },
    },

    subtitleNoWrap: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: 'block',
      transform: 'translateY(1.52px)',
    },
    postMetaTopPubLinkText: {
      paddingRight: '4px',
      fontSize: 13,
      maxHeight: 16,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontWeight: 500,
      color: 'rgba(41, 41, 41, 1)',
      fontFamily: 'sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;',
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
    },
    postMetaTopPubLinkTextIn: {
      color: 'rgb(117, 117, 117)',
    },
    linkTopPubAvatar: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'WMPublicationsStreamHome' }
);

type OwnPublicationStreamHomeProps = {
  author: {
    [key: string]: any;
  };
  date: any | string;
  post?: {
    [key: string]: any;
  };
  baseDate: any | string;
  publication?: any;
  title?: string;
  readTime: number;
  image?: string;
  subtitle?: string;
  imageLink: string;
  titleLink: string;
  useBackgroundPreview?: boolean;
  withImage?: boolean;
  imagePosition?: string;
  size?: string;
  subtitleNoWrap?: boolean;
  titleEl?: React.ReactNode;
  imageWEBP?: string;
  authorPosition?: 'top' | 'bottom';
};

const defaultProps = {
  useBackgroundPreview: false,
  subtitleNoWrap: false,
  publication: false,
  post: null,
  image: '',
  titleEl: 'h2',
  imageWEBP: '',
  title: 'Untitle story',
  subtitle: 'Unsubtitle story',
  withImage: false,
  imagePosition: 'right',
  size: 'default',
  authorPosition: 'top',
};

type PublicationStreamHomeProps = OwnPublicationStreamHomeProps;
const PublicationStreamHome = (props: PublicationStreamHomeProps) => {
  const classes = useStyles();
  const intl = useIntl();

  const localeContext: any = useLocale();

  const {
    title,
    subtitle,
    withImage,
    image,
    imageWEBP,
    titleEl,
    imageLink,
    titleLink,
    author,
    date,
    post,
    baseDate,
    readTime,
    imagePosition,
    size,
    publication,
    useBackgroundPreview,
    subtitleNoWrap,
    authorPosition,
  } = props;

  const { dateFns } = localeContext;

  const styleBg = image ? { backgroundImage: `url("${image}")` } : {};
  const styleBgWEBP = imageWEBP ? { backgroundImage: `url("${imageWEBP}")` } : false;

  const { username, name } = author;

  const authorName = name || username;

  const avatarPub =
    publication?.avatar &&
    getImage(publication?.avatar.md5, 'fit_c_40x40', publication?.avatar.format);

  const componentFooterDate = (
    <Typography className={(classes as any).postMeta} variant="caption">
      <time dateTime={date}>
        {formatDistance(new Date(date), baseDate, { locale: dateFns, addSuffix: true })}
      </time>
      <MiddotDivider />
      {intl.formatMessage(
        {
          id: 'readingTime',
          defaultMessage: '{readingTime} min read',
        },
        {
          readingTime: readTime,
        }
      )}
      {post && (post as any).claps > 0 && (
        <span className={(classes as any).spanIconStar}>
          <IconStar className={(classes as any).iconStar} />
        </span>
      )}
    </Typography>
  );

  return (
    <Grid
      direction={size === 'large' || size === 'medium' ? 'column' : 'row'}
      component="article"
      wrap="nowrap"
      container
      className={classNames((classes as any).container, classes[`containerSize${size}`], {
        [(classes as any).containerImageLeft]: imagePosition === 'left',
      })}
    >
      {imagePosition === 'left' && (
        <Grid
          item
          className={classNames((classes as any).imagePreview, classes[`imagePreviewSize${size}`])}
        >
          {withImage && (
            <>
              <Link
                aria-label={title}
                style={styleBg}
                to={imageLink}
                className={classNames((classes as any).linkImage, {
                  [(classes as any).linkImageWithBackground]: useBackgroundPreview,
                  'img-no-webp': !!styleBgWEBP,
                })}
              />
              {styleBgWEBP && (
                <Link
                  aria-label={title}
                  style={styleBgWEBP}
                  to={imageLink}
                  className={classNames((classes as any).linkImage, {
                    [(classes as any).linkImageWithBackground]: useBackgroundPreview,
                    'img-webp': !!styleBgWEBP,
                  })}
                />
              )}
            </>
          )}
        </Grid>
      )}
      <Grid
        item
        xs="auto"
        zeroMinWidth
        className={classNames(
          (classes as any).containerContent,
          classes[`containerContentImagePosition${imagePosition}`],
          classes[`containerContentSize${size}`]
        )}
      >
        {authorPosition === 'top' && (
          <div className={(classes as any).containerPostMetaTop}>
            {!!avatarPub && !!publication?.name && (
              <Link className={classes.linkTopPubAvatar} to={`/${(publication as any).slug}`}>
                <img
                  width="20"
                  height="20"
                  className={classes.pubAvatar}
                  src={avatarPub}
                  alt={publication.name}
                />
              </Link>
            )}
            <div className={classes.containerPostMetaRightTop}>
              <Link to={`/@${username}`}>
                <Typography component="h4" className={classes.postMetaTopPubLinkText}>
                  {authorName}
                </Typography>
              </Link>
              {publication && !!(publication as any).name && (
                <Typography component="div" sx={{ display: 'flex' }}>
                  <Typography
                    className={`${classes.postMetaTopPubLinkText} ${classes.postMetaTopPubLinkTextIn}`}
                  >
                    {intl.formatMessage({
                      id: 'in',
                      defaultMessage: 'in',
                    })}
                  </Typography>
                  <Link to={`/${(publication as any).slug}`}>
                    <Typography component="h4" className={classes.postMetaTopPubLinkText}>
                      {(publication as any).name}
                    </Typography>
                  </Link>
                </Typography>
              )}
            </div>
          </div>
        )}
        <div className={(classes as any).titleAndSubtitleContainer}>
          <Link to={titleLink}>
            <Typography
              className={classNames(
                (classes as any).titlePlaceHolder,
                classes[`titlePlaceHolderSize${size}`]
              )}
              variant="h3"
              // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
              component={titleEl}
            >
              {title}
            </Typography>
          </Link>
          <Link to={titleLink}>
            <Typography
              variant="caption"
              component="div"
              noWrap={size === 'small' || size === 'medium'}
              className={classNames((classes as any).subtitle, classes[`subtitleSize${size}`], {
                [(classes as any).subtitleNoWrap]: subtitleNoWrap,
              })}
            >
              {subtitle}
            </Typography>
          </Link>
        </div>
        <div className={classNames((classes as any).containerFooter, 'clearfix')}>
          <Grid container>
            {authorPosition === 'bottom' && (
              <Grid item xs={12} className={(classes as any).containerPostMeta}>
                <Link className={(classes as any).linkPostMeta} to={`/@${username}`}>
                  {authorName}
                </Link>
                {publication && !!(publication as any).name && (
                  <>
                    {' '}
                    {intl.formatMessage({
                      id: 'in',
                      defaultMessage: 'in',
                    })}{' '}
                    <Link
                      className={(classes as any).linkPostMeta}
                      to={`/${(publication as any).slug}`}
                    >
                      {(publication as any).name}
                    </Link>
                  </>
                )}
              </Grid>
            )}
            <Grid item xs={12} className={(classes as any).containerPostMeta}>
              {post && (post as any).contentUpdatedAt && (post as any).contentUpdatedAt !== date ? (
                <Tooltip
                  placement="top"
                  title={
                    <>
                      {intl.formatMessage({
                        id: 'Updated',
                        defaultMessage: 'Updated',
                      })}{' '}
                      {formatDistance(new Date((post as any).contentUpdatedAt), baseDate, {
                        locale: dateFns,
                        addSuffix: true,
                      })}
                    </>
                  }
                  arrow
                >
                  {componentFooterDate}
                </Tooltip>
              ) : (
                componentFooterDate
              )}
            </Grid>
          </Grid>
        </div>
      </Grid>
      {imagePosition === 'right' && (
        <Grid item className={(classes as any).imagePreview}>
          {withImage && (
            <>
              <Link
                aria-label={title}
                style={styleBg}
                to={imageLink}
                className={`${(classes as any).linkImage} ${styleBgWEBP ? 'img-no-webp' : ''}`}
              />
              {styleBgWEBP && (
                <Link
                  aria-label={title}
                  style={styleBgWEBP}
                  to={imageLink}
                  className={`${(classes as any).linkImage} ${styleBgWEBP ? 'img-webp' : ''}`}
                />
              )}
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
};

PublicationStreamHome.defaultProps = defaultProps;

export default PublicationStreamHome;
